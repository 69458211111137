<template>
  <div
    class="relative w-full py-2 px-4 hover:bg-blue-100"
    @mouseover="showSubmenu = true"
    @mouseleave="showSubmenu = false"
  >
    <span class="flex items-center">
      {{ $t("FUNDER_OFFERS.MANUAL_STATUS") }}
      <icon-base
        icon="arrow-up"
        width="10"
        height="6"
        class="transform -rotate-90 ml-2"
      />
    </span>
    <ul
      v-if="showSubmenu"
      class="absolute top-0 left-0 transform -translate-x-full flex flex-col items-start justify-start bg-white shadow-md w-max text-left rounded cursor-pointer"
    >
      <li
        v-for="(status, index) in offerStatuses"
        :key="index"
        @click.prevent.stop="updateOffersStatus(index)"
        class="py-2 px-4 hover:bg-blue-100 w-full"
      >
        {{ status }}
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import type { PropType } from "vue";
import { ref, computed } from "vue";
import { useDeals } from "@/hooks/deals";
import { useStore } from "vuex";
import type { IOffer } from "@/models/funders";

const props = defineProps({
  offer: {
    type: Object as PropType<IOffer>,
    required: true
  }
});

const { getters, dispatch } = useStore();
const { isActiveDealSelfFunded } = useDeals();

const showSubmenu = ref(false);

const offerStatuses = computed(() => {
  const all: Record<string, string> = getters["options/offerStatuses"];

  if (!isActiveDealSelfFunded.value) {
    return all;
  }

  const filteredEntries = Object.entries(all).filter(
    (statusEntry) =>
      isActiveDealSelfFunded.value &&
      ["accepted", "declined"].includes(statusEntry[1].toLowerCase())
  );

  return Object.fromEntries(filteredEntries);
});

const updateOffersStatus = async (status: string | number) => {
  const { id } = props.offer;

  await dispatch("applications/updateOfferStatus", {
    id,
    status
  });
};
</script>
